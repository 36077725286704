
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import {designation_list} from "@/core/data/genericData";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "edit-kmp-modal",
  directives: {mask},
  props: {
    data: {
        type: Object
    }
  },
  components: {
    
    },
  setup(props) {
    const store = useStore();
    const designation_item = designation_list;    
    const formRef = ref<null | HTMLFormElement>(null);
    const editkmpModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingDin = ref<boolean>(false);
    const router = useRouter();    
    var formData = ref({})
    // const formData = ref({
    //   name: "",      
    //   din : "",
    //   dob : "",
    //   gender : "",      
    //   mobile : "",
    //   email : "",
    //   designation_select : ""
    // });    

    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",          
        },        
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },        
      ],
      mobile: [
        {
          required: true,
          message: "Mobile is required",
          trigger: "change",
          min: 10,
          max: 10
        },
      ],
      // din: [
      //   {
      //     required: true,
      //     message: "DIN is required",
      //     trigger: "change",
      //     min: 8,
      //     max: 8
      //   },
      // ],      
      dob: [
        {
          required: true,
          message: "DOB is required",
          trigger: "change",
        },
      ],
      designation_select: [
        {
          required: true,
          message: "Designation is required",
          trigger: "change",
        },
      ],
      gender: [
        {
          required: true,
          message: "Gender is required",
          trigger: "change",
        },
      ]       
    });  
    
     const setFormData = async (data) => {    
        console.log(data.ids);
        var j = 0;

        try {

        var values = { "kmp_id": data.ids ,"kmp_din"  :  "", "page" : 1, "records_per_page" :1}
         await store.dispatch(Actions.CUST_GET_KMP_LIST, values).then(({ data }) => {       
          formData.value = {
            id : data.kmp_id,
            name: data.kmp_name,
            din: data.kmp_din,
            email : data.kmp_email_id,
            gender : data.kmp_gender_name,
            mobile : data.kmp_mobile_no,
            dob : data.kmp_dob,
            designation_select : data.kmp_designation_id,
            active : true,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    const setKmpData = async (data) => {

      var user = JSON.parse(JwtService.getToken());
      var dat = new Date(data.dob)
      var dt = ("0" + dat.getDate()).slice(-2)
      var mo = ("0" + (dat.getMonth() + 1)).slice(-2)
      var yy = dat.getFullYear()
      var dtt = (yy+'-'+mo+'-'+dt);

      const db_data = {
        "kmp_id": data.id,
        "kmp_name": data.name,
        "kmp_din": data.din,
        "kmp_designation_id" : data.designation_select,
        "kmp_gender_id" : data.gender == "Male" ? 1 : 2,
        "kmp_email_id" : data.email,
        "kmp_mobile_no" : data.mobile,
        "kmp_dob" : dtt,
        "active": 1,
        "user_id": user.user_id
        
        }                                                                                                                                                                                                                                               

      await store.dispatch(Actions.KMP_CREATE_UPDATE, db_data).then(({ data }) => {

        if (data){

            setTimeout(() => {
              loading.value = false;
              hideModal(editkmpModalRef.value);
              Swal.fire({
                text: "KMP has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editkmpModalRef.value);   
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }    

    const submit = async () => {
      
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        if (valid) {
          await setKmpData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

     onMounted( async () => {      
      await setFormData(props.data);
      });

    return {
      formData,
      designation_item,
      rules,
      submit,
      formRef,
      loading,
      loadingDin,
      editkmpModalRef,
    };
  },
});
